import { BellNotificationFiltersProps } from "components/dist/organisms/BellNotification/BellNotification.types";
import { useRouter } from "next/router";
import { useReducer } from "react";
import { useSubscription } from "react-stomp-hooks";
import { useAuth } from "src/hooks/use-auth";
import { ExtendedNotificationBellDto, useGetBellNotificationsUnreadCountQuery, useGetNotificationsQuery, useMarkAllNotificationsAsReadMutation, useMarkNotificationAsReadMutation } from "src/services/notificationApi";
import { useMediaQuery } from "usehooks-ts";

import { useBellNotificationInfiniteDataLoader } from "./bell-notification-list-infinite-data-loader.hook";

interface State {
    isPopoverOpen: boolean;
    activeTab: BellNotificationFiltersProps['activeTab'];
    activeFilter: BellNotificationFiltersProps['activeFilter'];
}

const initialState: State = {
    isPopoverOpen: false,
    activeTab: "ALL",
    activeFilter: "ALL",
};

type Action =
    | { type: 'SET_POPOVER_OPEN', payload: boolean }
    | { type: 'SET_ACTIVE_TAB', payload: BellNotificationFiltersProps['activeTab'] }
    | { type: 'SET_ACTIVE_FILTER', payload: BellNotificationFiltersProps['activeFilter'] };

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'SET_POPOVER_OPEN':
            return { ...state, isPopoverOpen: action.payload };
        case 'SET_ACTIVE_TAB':
            return { ...state, activeTab: action.payload };
        case 'SET_ACTIVE_FILTER':
            return { ...state, activeFilter: action.payload };
        default:
            return state;
    }
};

export const useBellNotificationState = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { user } = useAuth();
    const router = useRouter();
    const { isPopoverOpen, activeTab, activeFilter } = state;
    const { notifications, isLoadingNotifications, inViewReference } = useBellNotificationInfiniteDataLoader({ activeTab, activeFilter });
    const { data: notificationsCount, refetch: refetchCount } = useGetBellNotificationsUnreadCountQuery();
    const isMobile = useMediaQuery('(max-width: 640px)');
    useSubscription(`/topic/notification/bell/${user.id}`, () => {
        refetchCount();
    });

    const [markNotificationAsRead] = useMarkNotificationAsReadMutation();

    const [markAllAsRead] = useMarkAllNotificationsAsReadMutation();

    const setIsPopoverOpen = (isOpen: boolean) => dispatch({ type: 'SET_POPOVER_OPEN', payload: isOpen });

    const setActiveTab = (tab: BellNotificationFiltersProps['activeTab']) => dispatch({ type: 'SET_ACTIVE_TAB', payload: tab });

    const setActiveFilter = (filter: BellNotificationFiltersProps['activeFilter']) => dispatch({ type: 'SET_ACTIVE_FILTER', payload: filter });

    const onMarkAllAsRead = () => {
        const [firstNotification] = notifications;
        markAllAsRead({
            notificationId: firstNotification.id
        });
    }

    const onNotificationClick = (id: string) => {
        const notification = notifications.find(n => n.id === id);
        if (notification?.link) {
            router.push(notification.link)
            if (isMobile) {
                setIsPopoverOpen(false);
            }
        }
    };

    const onNotificationRead = (id: string) => {
        markNotificationAsRead({
            notificationId: id
        })
    };

    return {
        onNotificationClick,
        onNotificationRead,
        onPopoverOpenChange: setIsPopoverOpen,
        onMarkAllAsRead,
        setActiveFilter,
        inViewReference,
        isMobile,
        groupedNotifications: groupNotificationsByDate(notifications),
        isLoading: isLoadingNotifications,
        notifications,
        isPopoverOpen,
        activeFilter,
        activeTab,
        totalUnread: notificationsCount?.totalUnread ?? 0,
        totalUnreadAssignedToMe: notificationsCount?.totalUnreadAssignedToMe ?? 0,
        setActiveTab,
    } as const;
};


// a function that takes a list of notifications and group them
// by Today, Yesterday, and the older ones
// the function should return an object with the following structure:
// {
//     today: Notification[],
//     yesterday: Notification[],
//     older: Notification[]
// }
const groupNotificationsByDate = (notifications: ExtendedNotificationBellDto[]): { today: ExtendedNotificationBellDto[], yesterday: ExtendedNotificationBellDto[], older: ExtendedNotificationBellDto[] } => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const grouped = notifications.reduce((acc, notification) => {
        const date = new Date(notification.dateTimeNotification);
        if (date.toDateString() === today.toDateString()) {
            acc.today.push(notification);
        } else if (date.toDateString() === yesterday.toDateString()) {
            acc.yesterday.push(notification);
        } else {
            acc.older.push(notification);
        }
        return acc;
    }, { today: [], yesterday: [], older: [] } as { today: ExtendedNotificationBellDto[], yesterday: ExtendedNotificationBellDto[], older: ExtendedNotificationBellDto[] });

    return grouped;
}

import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import CopyButton from 'components/dist/molecules/CopyButton';
import ScreenSizeDialog from 'components/dist/molecules/ScreenSizeDialog';

const channels = [{
    title: "Email address",
    value: "support@sherpas.ai",
    icon: "Mail",
    isCopyable: true
}, {
    title: "Phone and SMS",
    value: "385-501-2151",
    icon: "Phone",
    isCopyable: true
}, {
    title: "Hours of Operation",
    value: "9am-5pm MST",
    icon: "Clock",
    isCopyable: false
}
] as const

export const ContactSupport = () => {
    return (<Stack>
        <Stack className='px-4 py-2' space="sm" row items="center">
            <Text size="sm" className="flex-1" weight="medium" >
                Contact Support
            </Text>
            <Dialog.Close asChild>
                <Button variant="secondary" size="sm" circle>
                    <Icon name="Cancel" width={24} height={24} strokeWidth={1.5} />
                </Button>
            </Dialog.Close>
        </Stack>
        <Separator />
        <Stack space="lg" className='px-5 pt-4 pb-6'>
            {channels.map(channel =>
                <Stack row key={channel.title} space="md" className='border border-gray-neutral-80 rounded-xl p-4 hover:bg-blue-50'>
                    <Stack className='rounded-full bg-gray-neutral-30 w-10 h-10' items="center" justify='center'>
                        <Icon name={channel.icon} width={24} height={24} strokeWidth={2} />
                    </Stack>
                    <Stack >
                        <Text weight="medium" size="sm">{channel.title}</Text>
                        <Text size="sm" as="div" variant={channel.isCopyable ? "blue" : "default"} className="group min-h-8 items-center flex flex-row gap-1">
                            {channel.value}
                            {channel.isCopyable && <CopyButton text={channel.value} size="custom" className='py-0 md:hidden group-hover:inline-flex shrink-0' />}
                        </Text>
                    </Stack>
                </Stack>
            )}
        </Stack>
    </Stack>)
}
import { j as t } from "./jsx-runtime-B6kdoens.js";
import { S as a } from "./Stack-DHVjAP0n.js";
import { c as d, g as o } from "./utils-CJ9afRe1.js";
import "./Accordion-Bmh6MwwT.js";
import { A as h } from "./Avatar-95vFNu6x.js";
import { B as b } from "./Badge-C0bj4DFU.js";
import { B as m } from "./Button-BrPm3sL-.js";
import { D as r } from "./DropdownMenu-BPBYPOOf.js";
import { I as l } from "./Icon-GJXsxHcP.js";
import "./Input-vl7Xx2ol.js";
import "./Label-Bzu7sDuW.js";
import { T as i } from "./Text-Bn-rHqca.js";
import "./Tooltip-Dy7rqQaX.js";
import { forwardRef as k } from "react";
import "./Switch-0yKWFNP4.js";
import "./Toast-CUzkTEdg.js";
import "./Toggle-Cn0qt44_.js";
import { u as v } from "./useMediaQuery-BLIs2rKV.js";
import { D as A } from "./DateTimeDisplay.component-43rm4kmn.js";
const u = () => /* @__PURE__ */ t.jsxs(a, { space: "lg", justify: "center", items: "center", className: "flex-1", children: [
  /* @__PURE__ */ t.jsx("div", { className: "size-16 bg-black-10 rounded-full flex items-center justify-center", children: /* @__PURE__ */ t.jsx(l, { name: "BigBell", width: 34, height: 40 }) }),
  /* @__PURE__ */ t.jsx(i, { className: "py-2 px-4", center: !0, size: "sm", children: "You're all caught up! No notifications to show" })
] });
u.displayName = "BellNotificationEmpty";
const j = "rounded-none inline-flex gap-2 border-b-[3px] text-gray-neutral-70 px-2", x = {
  ALL: "All",
  UNREAD: "Unread",
  READ: "Read"
}, g = (e) => {
  const n = v("(max-width: 640px)");
  return /* @__PURE__ */ t.jsxs(a, { row: !0, className: "px-3 pt-5 sm:pt-2", children: [
    /* @__PURE__ */ t.jsxs(a, { row: !0, className: "flex-1", children: [
      /* @__PURE__ */ t.jsxs(
        m,
        {
          onClick: () => e.onTabChange("ALL"),
          className: d(j, {
            "text-blue-100 border-b-blue-100 hover:text-blue-100": e.activeTab === "ALL"
          }),
          variant: "ghost",
          children: [
            "All ",
            e.totalUnread > 0 && /* @__PURE__ */ t.jsx(
              b,
              {
                size: "md",
                circle: !0,
                variant: "secondary",
                children: e.totalUnread
              }
            )
          ]
        }
      ),
      /* @__PURE__ */ t.jsxs(
        m,
        {
          onClick: () => e.onTabChange("ASSIGNED_TO_ME"),
          className: d(j, {
            "text-blue-100 border-b-blue-100 hover:text-blue-100": e.activeTab === "ASSIGNED_TO_ME"
          }),
          variant: "ghost",
          children: [
            "Assigned to Me ",
            e.totalUnreadAssignedToMe > 0 && /* @__PURE__ */ t.jsx(
              b,
              {
                size: "md",
                circle: !0,
                variant: "secondary",
                children: e.totalUnread
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ t.jsxs(
      a,
      {
        items: "center",
        row: !0,
        children: [
          e.totalUnread > 0 && !n && /* @__PURE__ */ t.jsx(
            m,
            {
              onClick: e.onMarkAllAsRead,
              variant: "ghost",
              className: "text-xs text-blue-100 rounded-none",
              children: "Mark All as Read"
            }
          ),
          /* @__PURE__ */ t.jsxs(r, { children: [
            /* @__PURE__ */ t.jsxs(r.Trigger, { className: "inline-flex flex-row items-center justify-between text-sm gap-1 p-1 w-24 bg-white rounded-lg", children: [
              /* @__PURE__ */ t.jsx(l, { name: "FilterList", width: 16, height: 16, strokeWidth: 2, className: "text-black" }),
              /* @__PURE__ */ t.jsx("span", { children: x[e.activeFilter] }),
              /* @__PURE__ */ t.jsx(l, { name: "NavArrowDown", width: 16, height: 16, strokeWidth: 2, className: "text-black" })
            ] }),
            /* @__PURE__ */ t.jsxs(
              r.Content,
              {
                align: "end",
                className: "w-full gap-0 sm:w-44 sm:max-w-full p-1.5 sm:p-1.5 overflow-hidden",
                children: [
                  /* @__PURE__ */ t.jsxs(
                    r.Item,
                    {
                      onSelect: () => e.onFilterChange("ALL"),
                      className: "gap-2",
                      children: [
                        /* @__PURE__ */ t.jsx(l, { name: "Bell", width: 20, height: 20, strokeWidth: 1.5, className: "text-black" }),
                        x.ALL
                      ]
                    }
                  ),
                  /* @__PURE__ */ t.jsxs(
                    r.Item,
                    {
                      onSelect: () => e.onFilterChange("UNREAD"),
                      className: "gap-2",
                      children: [
                        /* @__PURE__ */ t.jsx(l, { name: "Book", width: 20, height: 20, strokeWidth: 1.5, className: "text-black" }),
                        x.UNREAD
                      ]
                    }
                  ),
                  /* @__PURE__ */ t.jsxs(
                    r.Item,
                    {
                      onSelect: () => e.onFilterChange("READ"),
                      className: "gap-2",
                      children: [
                        /* @__PURE__ */ t.jsx(l, { name: "OpenBook", width: 20, height: 20, strokeWidth: 1.5, className: "text-black" }),
                        x.READ
                      ]
                    }
                  )
                ]
              }
            )
          ] })
        ]
      }
    )
  ] });
};
g.displayName = "BellNotificationFilters";
const N = (e) => /* @__PURE__ */ t.jsxs(a, { className: "bg-white py-2 px-3 sm:px-4", items: "center", row: !0, children: [
  /* @__PURE__ */ t.jsx(
    m,
    {
      onClick: e.onBackClick,
      size: "sm",
      variant: "ghost",
      className: "sm:hidden p-1",
      children: /* @__PURE__ */ t.jsx(l, { name: "NavArrowLeft", width: 20, height: 20, className: "text-black-primary", strokeWidth: 1.5 })
    }
  ),
  /* @__PURE__ */ t.jsx(i, { size: "sm", weight: "medium", className: "flex-1", children: "Notifications" }),
  e.totalUnread > 0 && /* @__PURE__ */ t.jsx(
    m,
    {
      onClick: e.onMarkAllAsRead,
      variant: "ghost",
      className: "text-xs px-0 sm:hidden text-blue-100 rounded-none",
      children: "Mark All as Read"
    }
  )
] });
N.displayName = "BellNotificationHeader";
const T = (e) => /* @__PURE__ */ t.jsxs(a, { space: "sm", className: "bg-white border border-gray-neutral-80 p-3 rounded-md max-w-full w-96 overflow-hidden", children: [
  /* @__PURE__ */ t.jsx(i, { size: "xs", weight: "medium", children: e.threadTitle }),
  /* @__PURE__ */ t.jsx(i, { truncate: !0, size: "xs", children: e.messagePreview })
] }), w = k((e, n) => /* @__PURE__ */ t.jsxs(
  a,
  {
    as: "li",
    onClick: () => {
      var s;
      return (s = e.onClick) == null ? void 0 : s.call(e, e.id);
    },
    row: !0,
    ref: n,
    items: "start",
    space: "sm",
    className: d("bg-white pl-3 pt-4", {
      "bg-blue-10": !e.isRead,
      "cursor-pointer hover:bg-gray-neutral-30": !!e.onClick,
      "cursor-default": !e.onClick
    }),
    children: [
      /* @__PURE__ */ t.jsxs(a, { items: "center", row: !0, space: "sm", children: [
        /* @__PURE__ */ t.jsx("div", { className: d("size-2 rounded-full", {
          "bg-blue-100": !e.isRead
        }) }),
        /* @__PURE__ */ t.jsxs(h, { size: "xs", children: [
          /* @__PURE__ */ t.jsx(
            h.Image,
            {
              src: e.user.avatarUrl,
              alt: e.user.initials
            }
          ),
          /* @__PURE__ */ t.jsx(h.Fallback, { className: d("text-[10px] font-medium", {
            "bg-blue-100 text-white": e.user.groupRole === "LENDER",
            "bg-yellow-60 text-black-primary": e.user.groupRole === "BORROWER",
            "bg-yellow-76 text-black-primary": e.user.groupRole === "CONTACT"
          }), children: e.user.initials })
        ] })
      ] }),
      /* @__PURE__ */ t.jsxs(a, { space: "xs", className: "flex-1 overflow-hidden border-b border-gray-neutral-80 pb-4 pr-3", children: [
        /* @__PURE__ */ t.jsxs(a, { row: !0, space: "xs", items: "center", children: [
          /* @__PURE__ */ t.jsx(i, { variant: "secondary", size: "xs", children: e.loanTitle }),
          /* @__PURE__ */ t.jsx(i, { variant: "secondary", size: "xss", className: "flex-1", children: e.loanId }),
          /* @__PURE__ */ t.jsx(i, { size: "xs", variant: "secondary", children: /* @__PURE__ */ t.jsx(
            A,
            {
              noAgo: !0,
              dateTime: e.dateTimeNotification,
              type: "DATE_TIME"
            }
          ) })
        ] }),
        /* @__PURE__ */ t.jsx(i, { size: "sm", children: e.title }),
        e.itemTitles.length > 0 && /* @__PURE__ */ t.jsxs(a, { as: "ul", space: "sm", className: "list-disc ml-4", children: [
          e.itemTitles.filter((s, c) => c < 1).map((s, c) => /* @__PURE__ */ t.jsx(i, { as: "li", underline: !0, size: "xs", variant: "blue", children: s }, c)),
          e.itemTitles.length > 1 && /* @__PURE__ */ t.jsxs(i, { as: "li", size: "xs", underline: !0, variant: "blue", children: [
            "+",
            e.itemTitles.length - 1,
            " items"
          ] })
        ] }),
        e.messagePreview && /* @__PURE__ */ t.jsx(
          T,
          {
            threadTitle: e.threadTitle,
            messagePreview: e.messagePreview
          }
        )
      ] })
    ]
  }
));
w.displayName = "BellNotificationITem";
const f = (e) => /* @__PURE__ */ t.jsx(a, { space: "sm", className: "flex-1 overflow-auto overscroll-contain scrollbar-stable w-full", children: e.children });
f.displayName = "BellNotificationList";
const B = (e) => {
  const n = o(e.children, N.displayName), s = o(e.children, g.displayName), c = o(e.children, u.displayName), y = o(e.children, f.displayName);
  return /* @__PURE__ */ t.jsxs(a, { className: "bg-black-10 sm:bg-white border border-gray-neutral-80 divide-y divide-gray-neutral-80 w-[504px] max-w-full sm:rounded-md h-full sm:h-[670px] max-h-full", children: [
    n,
    s,
    c,
    y
  ] });
}, q = Object.assign(B, {
  Header: N,
  Filters: g,
  Empty: u,
  List: f,
  Item: w
});
export {
  q as default
};

import { BellNotificationFiltersProps } from "components/dist/organisms/BellNotification/BellNotification.types";
import { useEffect, useState } from "react";
import { useInView } from "react-cool-inview";
import { useSubscription } from "react-stomp-hooks";
import { useAuth } from "src/hooks/use-auth";
import { ExtendedNotificationBellDto, useGetNotificationsQuery, useLazyGetNotificationsQuery } from "src/services/notificationApi";

interface Props {
    activeTab: BellNotificationFiltersProps['activeTab'];
    activeFilter: BellNotificationFiltersProps['activeFilter'];
}

interface Data {
    page: number;
    notifications: ExtendedNotificationBellDto[];
    allLoaded: boolean;
    isLoading: boolean;
}

const initialState: Data = {
    page: 0,
    notifications: [],
    allLoaded: false,
    isLoading: false
};

const NOTIFICATION_PAGE_SIZE = 8;

export const useBellNotificationInfiniteDataLoader = (props: Props) => {
    const [data, setData] = useState<Data>(initialState);

    const { user } = useAuth();
    const [getNotifications, { isLoading: isLoadingNotifications }] = useLazyGetNotificationsQuery();

    const { data: firstPageData = [] } = useGetNotificationsQuery({
        page: 0,
        size: NOTIFICATION_PAGE_SIZE,
        status: props.activeFilter,
        assigned: props.activeTab === 'ASSIGNED_TO_ME'
    })
    useSubscription(`/topic/notification/bell/${user.id}`, async () => {
        // get page 0 notifications
        // and append new notifications to the top of the list
        // if the notification is already in the list, skip it
        const notifications = await getNotifications({
            page: 0,
            size: NOTIFICATION_PAGE_SIZE,
            status: props.activeFilter,
            assigned: props.activeTab === 'ASSIGNED_TO_ME'
        }).unwrap();
        const newNotifications = notifications.filter(notification => !data.notifications.some(n => n.id === notification.id));
        setData(prevState => ({
            ...prevState,
            notifications: [...newNotifications, ...prevState.notifications]
        }));
    });

    const loadMoreNotifications = async () => {
        setData(prevState => ({
            ...prevState,
            isLoading: true
        }));
        const nextPage = data.page + 1;
        const notifications = await getNotifications({
            page: nextPage,
            size: NOTIFICATION_PAGE_SIZE,
            status: props.activeFilter,
            assigned: props.activeTab === 'ASSIGNED_TO_ME'
        }).unwrap();
        setData(prevState => ({
            ...prevState,
            isLoading: false,
            notifications: [...prevState.notifications, ...notifications],
            page: prevState.page + 1,
            allLoaded: notifications.length < NOTIFICATION_PAGE_SIZE
        }));
    };

    const { observe: inViewReference } = useInView({
        trackVisibility: !data.allLoaded && !isLoadingNotifications && firstPageData.length === NOTIFICATION_PAGE_SIZE,
        delay: 1000,
        onEnter: () => {
            console.log('onEnter')
            loadMoreNotifications();
        },
    });

    useEffect(() => {
        setData(initialState);
    }, [props.activeTab, props.activeFilter]);

    return {
        notifications: [...firstPageData, ...data.notifications],
        isLoadingNotifications: isLoadingNotifications || data.isLoading,
        inViewReference
    };
};